import { Buffer } from 'buffer';
import { dissoc, join, length } from 'ramda';
import { phr as api } from './axios';
import { getUpdateFormData } from './utils';

const endpoint = (path) => `/phr/${path}`;

const buildLibraryApi = (axios) => ({
  getAccessToken() {
    return axios.get(endpoint('access')).then(({ data }) => data);
  },
  getMy({
    sources,
    categories,
    searchTerm,
    tags,
    startDateFilter,
    endDateFilter,
    ...restParams
  }) {
    return axios
      .get(endpoint('my'), {
        params: {
          ...restParams,
          startDate: startDateFilter ? new Date(startDateFilter) : undefined,
          endDate: endDateFilter ? new Date(endDateFilter) : undefined,
          categories: length(categories) ? join(',', categories) : null,
          entryTags: length(tags) ? join(',', tags) : null,
          sourceTypes: length(sources) ? join(',', sources) : null,
          sort: length(restParams.sortBy)
            ? parseRequestSortParams(restParams.sortBy)
            : null,
          searchTerm,
        },
      })
      .then(({ data }) => data);
  },
  getFilteredObservations({ panelId, page, pageSize }) {
    return axios
      .get(endpoint(`observations`), {
        params: { panelId, page, pageSize },
      })
      .then(({ data }) => data);
  },
  deleteEntry(type, id) {
    return axios.delete(endpoint(`${type}/${id}`)).then(({ data }) => data);
  },
  getSources() {
    return axios.get(endpoint('sources')).then(({ data }) => data);
  },
  getExistingTags() {
    return axios.get(endpoint('entry-tags')).then(({ data }) => data);
  },
  getConnectedSources() {
    return axios.get(endpoint('user-sources')).then(({ data }) => data);
  },
  downloadData() {
    return axios
      .get(endpoint('download'), {
        responseType: 'blob',
      })
      .then(({ data }) => data);
  },

  addManualInputEntry(type, data) {
    return axios
      .post(endpoint(type), getUpdateFormData(data), {
        headers: Headers.FORMDATA,
      })
      .then(({ data }) => data);
  },

  editManualInputEntry(type, data) {
    return axios.put(endpoint(type), data).then(({ data }) => data);
  },
  getStoryblocksByIds(ids) {
    return axios
      .get(endpoint('library/storyblocks'), {
        params: { ids },
      })
      .then(({ data }) => data);
  },
  getDashboardHighlights() {
    return axios.get(endpoint('dashboard/highlights')).then(({ data }) => data);
  },
  addDashboardHighlight(data) {
    return axios
      .post(endpoint('dashboard/highlights'), data)
      .then(({ data }) => data);
  },
  deleteDashboardHighlightById(id) {
    return axios
      .delete(endpoint(`dashboard/highlights/${id}`))
      .then(({ data }) => data);
  },
  getDashboardPreferences() {
    // params (page, size. sort) are accepted from BE but not used on FE
    return axios
      .get(endpoint('dashboard/preferences'))
      .then(({ data }) => data);
  },
  getDashboardUnusedPreferences() {
    // params (page, size. sort) are accepted from BE but not used on FE
    return axios
      .get(endpoint('dashboard/preferences/unused'))
      .then(({ data }) => data);
  },
  getDashboardTopics(preferenceIds) {
    return axios
      .get(endpoint('dashboard/preference/topic'), {
        params: { preferenceIds },
      })
      .then(({ data }) => data);
  },
  addDashboardPreferences(data) {
    return axios
      .post(endpoint('dashboard/preferences'), data)
      .then(({ data }) => data);
  },
  addDashboardPreferenceByPHR(type, id) {
    return axios
      .put(endpoint(`dashboard/preferences/phr/${type}/${id}`))
      .then(({ data }) => data);
  },
  setDashboardPreferences(data) {
    return axios
      .post(endpoint('dashboard/preferences/list'), data)
      .then(({ data }) => data);
  },
  getFilteredCardDataById({ id, startDate, endDate, limit, sort }) {
    return axios
      .get(endpoint(`dashboard/cards/${id}/items`), {
        params: { startDate, endDate, limit, sort },
      })
      .then(({ data }) => data);
  },
  getPaginatedCardDataById(id, params) {
    return axios
      .get(endpoint(`dashboard/cards/${id}/items/paginated`), {
        params: parseRequestParams(params),
      })
      .then(({ data }) => data);
  },
  getCardById(id) {
    return axios
      .get(endpoint(`dashboard/cards/${id}`))
      .then(({ data }) => data);
  },
  emrAuthorization(authorization) {
    const { type, code, sourceExternalId, codeVerifier } = authorization;
    if (!type) {
      new Error('Type is missing!');
    }
    if (!code) {
      new Error('Code is missing!');
    }
    if (!sourceExternalId) {
      new Error('UserId is missing!');
    }
    return axios.get(
      `/${type}/auth-code?code=${code}&sourceExternalId=${sourceExternalId}&codeVerifier=${codeVerifier}`
    );
  },
  terraAuthorization(sourceExternalId) {
    const request = () =>
      axios
        .get(
          `terra/authentication-details?sourceExternalId=${sourceExternalId}`
        )
        .then(({ data }) => data);

    return request().catch((ex) => {
      //the bug: only once on new deploy request fails with CONNECTION RESET error
      if (ex?.response?.data?.code === 'UNCHECKED_IO') return request();
    });
  },
  disconnectEMRSource(sourceId) {
    return axios
      .put(`/emr/disconnect?sourceExternalId=${sourceId}`)
      .then(({ data }) => data);
  },
  disconnectTerraSource(sourceId) {
    return axios
      .delete(`terra/disconnect?sourceExternalId=${sourceId}`)
      .then(({ data }) => data);
  },

  getAttachmentById(id) {
    return axios
      .get(endpoint(`attachments/${id}`), {
        responseType: 'arraybuffer',
      })
      .then(({ data }) => Buffer.from(data, 'binary').toString('base64'));
  },

  getAttachmentThumbnail(id) {
    return axios
      .get(endpoint(`attachments/${id}/thumbnail`), {
        responseType: 'arraybuffer',
      })
      .then(({ data }) => Buffer.from(data, 'binary').toString('base64'));
  },

  getOcrAttachmentById(id) {
    return axios
      .get(endpoint(`ocr/attachments/${id}`), {
        responseType: 'arraybuffer',
      })
      .then(({ data }) => Buffer.from(data, 'binary').toString('base64'));
  },

  updateSource(data) {
    return axios.put(endpoint('user-sources'), data).then(({ data }) => data);
  },

  startSharing(data) {
    return axios.post(endpoint('sharings'), data).then(({ data }) => data);
  },

  stopSharing(id) {
    return axios
      .put(endpoint(`sharings/${id}/actions/deactivate`))
      .then(({ data }) => data);
  },

  getAllSharedLinks() {
    return axios.get(endpoint('sharings')).then(({ data }) => data);
  },

  getSharingInvitation(id) {
    return axios.get(endpoint(`sharings/${id}`)).then(({ data }) => data);
  },
  getSharedPreferences(id) {
    return axios
      .get(endpoint(`sharings/${id}/dashboard/preferences`))
      .then(({ data }) => data);
  },
  getSharedStoryBlocks(shareId, ids) {
    return axios
      .get(endpoint(`sharings/${shareId}/storyblocks`), {
        params: { ids },
      })
      .then(({ data }) => data);
  },
  getSharedTopics(id, preferenceIds) {
    return axios
      .get(endpoint(`sharings/${id}/dashboard/preference/topic`), {
        params: { preferenceIds },
      })
      .then(({ data }) => data);
  },
  getSharedFilteredCardDataById(
    id,
    { id: cardId, startDate, endDate, limit, sort }
  ) {
    return axios
      .get(endpoint(`sharings/${id}/dashboard/cards/${cardId}/items`), {
        params: { startDate, endDate, limit, sort },
      })
      .then(({ data }) => data);
  },
  getSharedPaginatedCardDataById(shareId, id, params) {
    return axios
      .get(
        endpoint(`sharings/${shareId}/dashboard/cards/${id}/items/paginated`),
        {
          params: parseRequestParams(params),
        }
      )
      .then(({ data }) => data);
  },
  getSharedCardById(shareId, id) {
    return axios
      .get(endpoint(`sharings/${shareId}/dashboard/cards/${id}`))
      .then(({ data }) => data);
  },
});

export default buildLibraryApi(api);

function parseRequestParams(params) {
  const pureParams = dissoc('sortBy', params);
  const parsedSortBy = parseRequestSortParams(params?.sortBy);

  if (params?.sortBy?.length) return { ...pureParams, sort: parsedSortBy };
  return pureParams;
}

function parseRequestSortParams(sortBy) {
  return sortBy
    ?.map(({ id, desc }) => `${id},${desc ? 'desc' : 'asc'}`)
    .join('&');
}
