import { filter, head, identity, props } from 'ramda';
import { useQuery } from 'react-query';

import { useLibraryApi } from 'library/libraryApiContext';
import { useMemo } from 'react';
import { MANUAL_INPUT_CATEGORY } from 'my-phr/layout/modules/ManualInputs/const';
import {
  Condition,
  Human,
  Medication,
  Microscope,
  Note,
  Stethoscope,
  Vaccine,
  Virus,
} from 'components/icons';

const getQueryKey = (query) => ['library', 'storyblock', query];
const STORYBLOCK_DEFINITION = 'storyblock_definition';

export function useStoryBlockQuery(query, category, options) {
  const api = useLibraryApi();
  const isMulti = Array.isArray(query);
  const ids = isMulti ? query : [query];

  return useQuery(
    getQueryKey(ids),
    () => api.getStoryBlocksById(ids).then(props(ids)).then(filter(Boolean)),
    {
      enabled: ids?.filter(Boolean).length > 0,
      select: isMulti ? identity : head,
      ...options,
    }
  );
}

export function useDefaultStoryBlock(type, category, options) {
  const api = useLibraryApi();
  const { data } =
    useQuery(
      getQueryKey(type),
      () => api.getDefaultStoryblock(type, category),
      options
    ) ?? [];

  if (data) {
    return category ? data.data : head(data.data);
  }
}

export function useGetManualInputCategories() {
  const allStoryblocks = useDefaultStoryBlock('', STORYBLOCK_DEFINITION);

  const typeMapping = useMemo(() => {
    return {
      allergyintolerance: 'ALLERGY',
      immunization: 'VACCINE',
      condition: 'CONDITION',
      procedure: 'PROCEDURE',
      observation: 'OBSERVATION',
      lab_panel: 'OBSERVATION_PANEL',
      medication: 'MEDICATION',
      note: 'NOTE',
    };
  }, []);

  const options = useMemo(() => {
    return [
      {
        label: 'my-phr.types.allergy_one',
        description: 'my-phr.types-hints.allergy',
        inputType: MANUAL_INPUT_CATEGORY.ALLERGY,
        icon: (props) => <Virus {...props} />,
      },
      {
        label: 'my-phr.types.condition_one',
        description: 'my-phr.types-hints.condition',
        inputType: MANUAL_INPUT_CATEGORY.CONDITION,
        icon: (props) => <Condition {...props} />,
      },
      {
        label: 'my-phr.types.measurement_one',
        description: 'my-phr.types-hints.measurement',
        inputType: MANUAL_INPUT_CATEGORY.MEASUREMENT,
        icon: (props) => <Human {...props} />,
      },

      {
        label: 'my-phr.types.medication_one',
        description: 'my-phr.types-hints.medication',
        inputType: MANUAL_INPUT_CATEGORY.MEDICATION,
        icon: (props) => <Medication {...props} />,
      },
      {
        label: 'my-phr.types.procedure_one',
        description: 'my-phr.types-hints.procedure',
        inputType: MANUAL_INPUT_CATEGORY.PROCEDURE,
        icon: (props) => <Stethoscope {...props} />,
      },
      {
        label: 'my-phr.types.observation_one',
        description: 'my-phr.types-hints.observation',
        inputType: MANUAL_INPUT_CATEGORY.OBSERVATION,
        icon: (props) => <Microscope {...props} />,
      },
      {
        label: 'my-phr.types.vaccine_one',
        description: 'my-phr.types-hints.vaccine',
        inputType: MANUAL_INPUT_CATEGORY.VACCINE,
        icon: (props) => <Vaccine {...props} />,
      },
      {
        label: 'my-phr.types.note_one',
        inputType: MANUAL_INPUT_CATEGORY.NOTE,
        icon: (props) => <Note {...props} />,
      },
    ];
  }, []);

  const categories = useMemo(() => {
    return allStoryblocks?.length > 0
      ? allStoryblocks.map((option) => ({
          inputType: typeMapping[option.filter?.type],
          icon: option.icon,
          label: option.name,
          filter: option.filter,
          description: option.description,
          synonyms: option.synonyms,
          isFeatured: option.isFeatured,
          fields: option.fields,
        }))
      : options;
  }, [allStoryblocks, options, typeMapping]);

  return { categories };
}
