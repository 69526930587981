import { createContext, useContext, useCallback, useEffect } from 'react';
import { useLocalStorage } from 'react-use';
import { add, set } from 'date-fns';
import { Roles, useAuth } from 'Auth';
import features from 'features';
import { useCategories, useDateRanges, useSourcesFilters } from 'my-phr/hooks';

const FiltersProviderStateContext = createContext(null);
const FiltersProviderUpdaterContext = createContext(null);

export const SOURCE_TYPES = {
  EMR: 'EMR',
  MANUAL: 'MANUAL',
  OCR: 'OCR',
  WEARABLE: 'WEARABLE',
};

export function getEnabledPlatformSourceTypes(isAdmin) {
  return [
    SOURCE_TYPES.MANUAL,
    features.PHR.PHR_WEARABLES_ENABLED && SOURCE_TYPES.WEARABLE,
    features.PHR.PHR_EMRS_ENABLED && SOURCE_TYPES.EMR,
    (features.PHR.OCR_ENABLED || isAdmin) && SOURCE_TYPES.OCR,
  ].filter(Boolean);
}

export const initialStateFiltersProvider = {
  sources: [],
  categories: [],
  tags: [],
  timelineDateRangeFilter: 'all',
  searchTerm: '',
};

const setZeroTimeDate = (date) =>
  set(date, {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

const getDateWithOffset = (offset) => setZeroTimeDate(add(new Date(), offset));

const key = 'timeline-filters';

function FiltersProvider({ children }) {
  const { authenticated, hasRole } = useAuth();

  const isAdmin = hasRole(Roles.ADMIN);

  const [filters, setFilters, removeFilters] = useLocalStorage(
    key,
    initialStateFiltersProvider
  );

  const categoriesFilters = useCategories(filters?.categories);

  const sourcesFilters = useSourcesFilters(filters?.sources);

  const dateRange = useDateRanges(filters?.timelineDateRangeFilter);

  useEffect(() => {
    if (!authenticated) {
      removeFilters();
    }
  }, [authenticated, removeFilters]);

  const setCategories = (value) => {
    if (
      filters?.categories.length > 0 &&
      value &&
      filters?.categories.includes(value[0])
    ) {
      const newSelected = filters?.categories.filter((el) =>
        value.every((v) => v !== el)
      );
      setFilters(() => ({ ...filters, categories: newSelected }));
    } else {
      setFilters(() => ({
        ...filters,
        categories: [...filters?.categories, ...value],
      }));
    }
  };

  const setTags = (tag) => {
    if (!tag || !tag?.length) return;
    const isSelected = tag && filters?.tags?.includes?.(tag);
    setFilters(() => {
      return {
        ...filters,
        tags: isSelected
          ? filters.tags.filter((value) => value !== tag)
          : filters?.tags
          ? [...filters?.tags, tag]
          : [tag],
      };
    });
  };

  const setSources = (value, selected) => {
    if (
      !selected &&
      filters?.sources?.length ===
        getEnabledPlatformSourceTypes(isAdmin)?.length
    ) {
      setFilters(() => ({
        ...filters,
        sources: [value],
      }));
      return;
    }

    if (
      filters?.sources?.length > 0 &&
      value &&
      filters?.sources?.includes(value)
    ) {
      const newSelected = filters?.sources.filter((el) => el !== value);
      setFilters(() => ({ ...filters, sources: newSelected }));
    } else {
      setFilters(() => ({
        ...filters,
        sources: [...filters?.sources, value],
      }));
    }
  };

  const setTimelineDateRangeFilter = ({ value }) => {
    let dateRangeFilter = {
      startDateFilter: undefined,
      endDateFilter: undefined,
    };
    if (value !== 'all') {
      dateRangeFilter = {
        startDateFilter: getDateWithOffset({ [value]: -1 }),
        endDateFilter: new Date(),
      };
    }
    setFilters(() => ({
      ...filters,
      ...dateRangeFilter,
      timelineDateRangeFilter: value,
    }));
  };

  return (
    <FiltersProviderStateContext.Provider
      value={{
        filters,
        categoriesFilters,
        sourcesFilters,
        dateRange,
      }}
    >
      <FiltersProviderUpdaterContext.Provider
        value={{
          setFilters,
          setCategories,
          setSources,
          setTimelineDateRangeFilter,
          setTags,
        }}
      >
        {children}
      </FiltersProviderUpdaterContext.Provider>
    </FiltersProviderStateContext.Provider>
  );
}

function useFiltersProviderState() {
  const contextState = useContext(FiltersProviderStateContext);
  if (typeof contextState === 'undefined') {
    throw new Error(
      'useFiltersProviderState must be used within a FiltersProvider'
    );
  }
  return contextState;
}

function useFiltersProviderUpdater() {
  const contextUpdater = useContext(FiltersProviderUpdaterContext);
  if (typeof contextUpdater === 'undefined') {
    throw new Error(
      'useFiltersProviderUpdater must be used within a FiltersProvider'
    );
  }
  return useCallback(contextUpdater, [contextUpdater]);
}

function useFiltersProvider() {
  return [useFiltersProviderState() || {}, useFiltersProviderUpdater() || {}];
}

export { FiltersProvider, useFiltersProvider };
