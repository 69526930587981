import { useMemo, useRef } from 'react';
import { DATE_PRESETS, PHR_DATA_TYPE } from 'my-phr/const';
import { safeDateFormat } from 'utils';
import { convertChartData } from 'my-phr/utils/conversion';
import {
  useDashboardPreferences,
  useDashboardTopics,
  useStoryBlockQuery,
} from './queryHooks';

export function useTimelineDataGrouping(data) {
  return useMemo(() => {
    if (!data) return {};
    const flatTimeline = data.pages?.reduce(
      (prev, curr) => [...prev, ...curr?.content],
      []
    );
    const filteredFlatTimeline = flatTimeline.filter(Boolean);

    const groupedByDateTimeline = [];
    const groupedData = {};
    for (const entry of filteredFlatTimeline) {
      const date = entry?.postedTs || entry?.updatedTs || null;
      const dateGroupKey = safeDateFormat({
        date,
        pattern: DATE_PRESETS.TIMESTAMP,
        fallback: new Date().toDateString(),
      });

      groupedByDateTimeline.push(dateGroupKey);

      if (!groupedData[dateGroupKey]) {
        groupedData[dateGroupKey] = [];
      }
      groupedData[dateGroupKey].push(entry);
    }

    return {
      groupedData,
      sortedDates: [...new Set(groupedByDateTimeline)].sort().reverse(),
    };
  }, [data]);
}

// mapping topics to card names to prevent screen flashing and layout shifting when dragging and reordering,
// because the card ids are re-generated when we update the order.
const mappedTopicsToCardNamesMap = new Map();
let shouldRefetchCardTopics = false;

const OBSERVATION_TYPES = [
  PHR_DATA_TYPE.OBSERVATION,
  PHR_DATA_TYPE.OBSERVATION_PANEL,
];

export function useDashboardCardsWithLibrary(options = {}) {
  const {
    usePreferences = useDashboardPreferences,
    useStoryBlocks = useStoryBlockQuery,
    useTopics = useDashboardTopics,
  } = options;

  const { data, isLoading, isFetching } = usePreferences({
    onSuccess: (res) => {
      res?.forEach((item) => {
        if (
          OBSERVATION_TYPES.includes(item?.type) &&
          !mappedTopicsToCardNamesMap.has(item.name)
        ) {
          mappedTopicsToCardNamesMap.set(item.name, { cardId: item.id });
          shouldRefetchCardTopics = true;
        }
      });
    },
  });

  const preferenceIds = data
    ?.filter((item) => OBSERVATION_TYPES.includes(item?.type))
    ?.map((x) => x?.id);

  const storyblockIds = data
    ?.map((item) => {
      if (item?.type === PHR_DATA_TYPE.OBSERVATION_PANEL) {
        return item?.dashboardPreferenceDtos
          .map((preference) => preference?.storyblockId)
          .filter(Boolean);
      } else {
        return item?.storyblockId;
      }
    })
    .filter(Boolean)
    .flat();

  const { data: storyblocks } = useStoryBlocks(storyblockIds, null);
  const refTopicsRefreshed = useRef(true);

  useTopics(preferenceIds, {
    enabled: shouldRefetchCardTopics && !isFetching,
    onSuccess: (res) => {
      mappedTopicsToCardNamesMap.forEach((value, key, map) => {
        const mappedTopicForCard = res[value?.cardId];
        if (mappedTopicForCard) {
          map.set(key, mappedTopicForCard);
        }
      });
      shouldRefetchCardTopics = false;
      refTopicsRefreshed.current = !refTopicsRefreshed;
    },
  });

  const storyblocksMap = useMemo(() => {
    return storyblocks?.reduce((map, storyblock) => {
      map[storyblock.id] = storyblock;
      return map;
    }, {});
  }, [storyblocks]);

  const combinedData = useMemo(
    () =>
      data?.map((preference) => {
        const storyblock = storyblocksMap?.[preference?.storyblockId];
        const parsedPanelPreferences = preference?.dashboardPreferenceDtos?.map(
          (preference) => {
            const storyblock = storyblocksMap?.[preference?.storyblockId];
            return { ...preference, storyblock: storyblock };
          }
        );

        const parsedItems = preference.items.map((item) => ({
          ...item,
          storyblock: storyblock,
        }));

        return preference?.type === PHR_DATA_TYPE.OBSERVATION_PANEL
          ? { ...preference, dashboardPreferenceDtos: parsedPanelPreferences }
          : { ...preference, items: parsedItems };
      }),
    [data, storyblocksMap]
  );

  const cardsWithLibraryData = useMemo(
    () => {
      if (!combinedData) return [];
      return combinedData?.map((item) => {
        const convertedCardChartItems =
          item.type === PHR_DATA_TYPE.OBSERVATION
            ? convertChartData(item.items)
            : item.items;

        const topic = mappedTopicsToCardNamesMap.get(item.name)
          ? mappedTopicsToCardNamesMap.get(item.name)
          : null;

        return {
          ...item,
          items: convertedCardChartItems,
          topics: topic ? [topic] : [],
        };
      });
    },
    // forcing re-evaluation after the topics are fetched
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [combinedData, refTopicsRefreshed.current]
  );

  return {
    cardsWithLibraryData,
    isFetching,
    isLoading,
    topicsMap: mappedTopicsToCardNamesMap,
  };
}
