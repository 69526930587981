import { useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { omit } from 'ramda';

import PHR_API from 'api/phr';
import { useApplyUnits } from 'my-phr/hooks';
import {
  useSharedHealthInfoDispatch,
  useSharedHealthInfoState,
} from 'my-phr/context/SharedHealthInfoProvider';
import { safeDateFormat } from 'utils';
import { DATE_PRESETS, PHR_ROUTES } from 'my-phr/const';
import { PHRApi } from 'api';
import { viewerPath } from 'journals/viewer/const';

const KEYS = {
  allSharedLinks: () => ['shared-links'],
  invitation: (id) => ['invitation', id],
  shared: (id) => ['shared', id],
  dashboard: (id) => [...KEYS.shared(id), 'dashboard'],
  storyblocks: (shareId, storyBlockIds) => [
    ...KEYS.shared(shareId),
    'storyblocks',
    storyBlockIds,
  ],
  topics: (id, preferenceIds) => [...KEYS.shared(id), 'topics', preferenceIds],
  cardDataById: (shareId, cardId) => [...KEYS.shared(shareId), 'card', cardId],
  filteredCardDataById: (id, cardId, startDate, endDate, limit, sort) => [
    ...KEYS.cardDataById(id, cardId),
    startDate,
    endDate,
    limit,
    sort,
  ],
  cardPaginatedDataById: (shareId, cardId, page, size, sortBy) => [
    ...KEYS.cardDataById(shareId, cardId),
    page,
    size,
    sortBy,
  ],
  cardById: (shareId, id) => [...KEYS.shared(shareId), 'card', id],
};

export function useIsShared() {
  return useRouteMatch([
    PHR_ROUTES.SHARED_DASHBOARD,
    PHR_ROUTES.SHARING_VIEW_LINK,
  ]);
}

export function useSharedLinks(options) {
  return useQuery(KEYS.allSharedLinks(), PHR_API.getAllSharedLinks, options);
}

export function useSharingInvitation(id, options) {
  const history = useHistory();
  const setSharedHealthInfo = useSharedHealthInfoDispatch();
  const onInvitationSuccess = useCallback(
    (invitation) => {
      const name = invitation?.senderName;
      const lastUpdated = invitation?.lastChange
        ? safeDateFormat({
            date: invitation.lastChange,
            pattern: DATE_PRESETS.DATE_LONG_MONTH,
          })
        : undefined;
      setSharedHealthInfo({ name, lastUpdated, shareId: invitation?.id });
    },
    [setSharedHealthInfo]
  );

  return useQuery(
    KEYS.invitation(),
    async () => PHR_API.getSharingInvitation(id),
    {
      onSuccess: onInvitationSuccess,
      onError: () => history.push(`/${viewerPath.EMBED404}`),
      cacheTime: Infinity,
      ...options,
    }
  );
}

export function useCreateSharedLink({ onSuccess }) {
  const queryClient = useQueryClient();

  return useMutation(PHR_API.startSharing, {
    onSuccess: () => {
      queryClient.refetchQueries(KEYS.allSharedLinks());
      onSuccess();
    },
  });
}

export function useStopSharedLink({ onSuccess }) {
  const queryClient = useQueryClient();

  return useMutation(PHR_API.stopSharing, {
    onSuccess: () => {
      queryClient.refetchQueries(KEYS.allSharedLinks());
      onSuccess();
    },
  });
}

export function useSharedDashboardPreferences(config) {
  const { shareId } = useSharedHealthInfoState();
  const applyUnitsToAllItems = useApplyUnits();

  return useQuery(
    KEYS.dashboard(shareId),
    () => PHR_API.getSharedPreferences(shareId),
    {
      select: (preferences) =>
        applyUnitsToAllItems(
          preferences.filter(
            (preference) => typeof preference?.orderNumber === 'number'
          )
        ),
      enabled: Boolean(shareId),
      cacheTime: Infinity,
      ...config,
    }
  );
}

export function useSharedStoryBlock(storyBlockIds, config) {
  const { shareId } = useSharedHealthInfoState();

  const storyBlockSet = new Set([].concat(storyBlockIds));
  const uniqueStoryblocksIds = Array.from(storyBlockSet);
  return useQuery(
    KEYS.storyblocks(shareId, storyBlockIds),
    () =>
      PHR_API.getSharedStoryBlocks(shareId, encodeURI(uniqueStoryblocksIds)),
    {
      enabled: Boolean(shareId) && Boolean(uniqueStoryblocksIds.length),
      cacheTime: Infinity,
      ...config,
    }
  );
}

export function useSharedTopics(preferenceIds, config) {
  const { shareId } = useSharedHealthInfoState();

  const { data: preferenceTopics, isLoading: isLoadingTopics } = useQuery(
    KEYS.topics(shareId, preferenceIds),
    () => PHR_API.getSharedTopics(shareId, encodeURI(preferenceIds)),
    {
      enabled: Boolean(shareId) && Boolean(preferenceIds?.length),
      cacheTime: Infinity,
      ...config,
    }
  );

  return { preferenceTopics, isLoadingTopics };
}

export function useSharedObservationById(cardId, params) {
  const { shareId } = useSharedHealthInfoState();
  const applyUnitsToAllItems = useApplyUnits();

  return useQuery(
    KEYS.cardPaginatedDataById(
      shareId,
      cardId,
      params?.page,
      params?.size,
      params?.sortBy
    ),
    () => PHRApi.getSharedPaginatedCardDataById(shareId, cardId, params),
    {
      select: applyUnitsToAllItems,
      keepPreviousData: true,
      enabled: Boolean(shareId) && Boolean(cardId),
      cacheTime: Infinity,
    }
  );
}

export function useSharedPaginatedListDataByType(args, options) {
  const { shareId } = useSharedHealthInfoState();

  const params = omit(['id', 'type'], args);
  const cardId = args?.id;

  return useQuery(
    KEYS.cardPaginatedDataById(
      shareId,
      cardId,
      params?.page,
      params?.size,
      params?.sortBy
    ),
    () => PHRApi.getSharedPaginatedCardDataById(shareId, cardId, params),
    {
      keepPreviousData: true,
      enabled: Boolean(shareId) && Boolean(cardId),
      cacheTime: Infinity,
      ...options,
    }
  );
}

export function useSharedPanelById(id) {
  const { shareId } = useSharedHealthInfoState();

  const applyUnitsToAllItems = useApplyUnits();
  const { data, isFetched } = useQuery(
    KEYS.cardById(shareId, id),
    () => PHRApi.getSharedCardById(shareId, id),
    {
      select: applyUnitsToAllItems,
      keepPreviousData: true,
      enabled: Boolean(shareId) && Boolean(id),
      cacheTime: Infinity,
    }
  );
  return { data, isFetched };
}

export function useSharedFilteredCardDataById(
  { id: cardId, startDate, endDate, limit, sort },
  config
) {
  const { shareId } = useSharedHealthInfoState();

  return useQuery(
    KEYS.filteredCardDataById(
      shareId,
      cardId,
      startDate,
      endDate,
      limit,
      (sort = 'effectiveTs,desc')
    ),
    () =>
      PHRApi.getSharedFilteredCardDataById(shareId, {
        id: cardId,
        startDate,
        endDate,
        limit,
        sort,
      }),
    {
      keepPreviousData: true,
      cacheTime: Infinity,
      ...config,
    }
  );
}
