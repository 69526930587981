import { Form } from 'components/forms';
import { useSearchSuggestions } from 'hooks';
import { STORYBLOCK_ENDPOINT } from 'my-phr/const';

import { useDefaultStoryBlock } from 'hooks/useStoryblockQuery';
import { includes } from 'ramda';
import {
  AUTOCOMPLETE_CATEGORIES,
  DEFAULT_STORYBLOCK_TYPES,
  MANUAL_INPUT_TYPE,
} from '../const';
import { useManualInputDefaultProps } from '../hooks';
import { useManualInputsDispatchContext } from '../ManualInputsContext';
import manualInputsRegistry from '../manualInputsRegistry';

const debounce = 0;

function matchesSearchParams(suggestion, searchParams) {
  const isTypeMatch = suggestion?.type === searchParams?.type;

  // TODO - this should be excluded on Library/Template search side - identify improvement in the future
  const shouldMatchCategory =
    (suggestion?.type !== DEFAULT_STORYBLOCK_TYPES.ALLERGY &&
      Boolean(suggestion?.category?.length > 0) &&
      !suggestion?.category?.includes(AUTOCOMPLETE_CATEGORIES.LAB_TEST)) ||
    Boolean(searchParams?.category?.length);

  if (shouldMatchCategory) {
    return (
      isTypeMatch && includes(searchParams.category, suggestion?.category || [])
    );
  }

  return isTypeMatch;
}

export function SearchInput({
  endpoint = STORYBLOCK_ENDPOINT,
  searchParams,
  ...rest
}) {
  const newProps = useManualInputDefaultProps(rest);

  const { onSuggestionDeleted } = useManualInputsDispatchContext();

  const { suggestions, fetch, refetch, clear, isFetched } =
    useSearchSuggestions(debounce, endpoint, searchParams);

  const inputType = DEFAULT_STORYBLOCK_TYPES[rest?.prefix];
  const defaultStoryBlock = useDefaultStoryBlock(inputType, null, {
    enabled: Boolean(inputType?.length),
  });

  const currentInputValue = newProps.getValues(`${newProps.prefix}.name.value`);

  const isLabPanel = Boolean(
    newProps.getValues(`${newProps.prefix}.observations.length`)
  );

  const currentCategorySuggestions = suggestions?.filter?.((entry) =>
    matchesSearchParams(entry, searchParams)
  );

  const otherCategoriesSuggestions = suggestions
    ?.filter?.((entry) => !matchesSearchParams(entry, searchParams))
    .map((entry, index) => ({
      isOtherCategory: true,
      isFirstItem: index === 0,
      ...entry,
    }));

  const defaultStoryBlockSuggestion = {
    name: '',
    addsNew: true,
    id: defaultStoryBlock?.id,
  };

  const addNewRecordSuggestion = isFetched ? [defaultStoryBlockSuggestion] : [];

  const suggestionToRender = [
    ...currentCategorySuggestions,
    ...addNewRecordSuggestion,
    ...otherCategoriesSuggestions,
  ];

  const handleOnEnterPress = (e, value) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      rest.setValue(rest.name, value);
    }
  };

  return (
    <Form.Suggestions
      {...newProps}
      searchSuggestionsProps={{
        suggestions: suggestionToRender,
        onFetch: fetch,
        onClear: currentInputValue
          ? isLabPanel
            ? onSuggestionDeleted
            : refetch
          : clear,
        onCategoryChange: rest?.onCategoryChange,
        onEnterPress: handleOnEnterPress,
      }}
      customContainer
      defaultSuggestion={defaultStoryBlockSuggestion}
    />
  );
}

manualInputsRegistry.register(MANUAL_INPUT_TYPE.SEARCH, SearchInput);
